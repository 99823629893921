* {
  margin: 0;
  padding: 0;
}

.App {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

#root {
  width: 100%;
}
